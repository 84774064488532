<template>
  <div class="bank-id-reject">
    <Container>
      <Card padding="large">
        <b-row>
          <b-col cols="12" sm="auto" class="text-center">
            <CloseSVG class="icon h1 text-error" />
          </b-col>
          <b-col>
            <Margins>
              <h1 class="h3 text-normal">
                {{ $t('SORRY_BUT_DECLINED') }}
              </h1>
              <p class="text-small">{{ $t('DECLINED_REASONS_TITLE') }}</p>
              <ul class="list m-4">
                <li>
                  <p class="text-small">
                    {{ $t('DECLINED_REASONS.REASON_1') }}
                  </p>
                </li>
                <li>
                  <p class="text-small">
                    {{ $t('DECLINED_REASONS.REASON_2') }}
                  </p>
                </li>
              </ul>
              <!--              <b-row align-h="center" class="margins__double">-->
              <!--                <b-col cols="auto">-->
              <!--                  <Button-->
              <!--                    v-if="!backOfficeLoggedIn"-->
              <!--                    border="none"-->
              <!--                    variant="inverse-primary"-->
              <!--                    @click="-->
              <!--                      $router.push({ name: ROUTES.CREDIT_CARD_PAYMENT.name })-->
              <!--                    "-->
              <!--                    >{{-->
              <!--                      $t('LOAN_APPLICATION_REJECT.BUY_WITH_CREDIT_CARD')-->
              <!--                    }}</Button-->
              <!--                  >-->
              <!--                </b-col>-->
              <!--              </b-row>-->
              <b-row align-h="center" class="margins__double">
                <b-col cols="auto">
                  <Button
                    @click="$router.push({ name: ROUTES.HOME.name })"
                    border="none"
                    variant="default"
                  >
                    {{ $t('RETURN_TO_HOME') }}
                  </Button>
                </b-col>
              </b-row>
            </Margins>
          </b-col>
        </b-row>
      </Card>
    </Container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Button, Card, Container, Margins } from '@/components';
import CloseSVG from '@/assets/images/times-circle-regular.svg';
import { constants } from '@/mixins';

export default {
  name: 'Reject',
  mixins: [constants],
  components: {
    Container,
    Card,
    Margins,
    Button,
    CloseSVG,
  },
  computed: {
    ...mapState(['isLoading', 'backOfficeLoggedIn']),
  },
};
</script>

<style lang="scss">
.list {
  list-style-type: disc;
}
</style>
